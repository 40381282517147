import { graphql, useStaticQuery } from 'gatsby';

export const useBranchData = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentstackBranchData {
				nodes {
					branch_retail
					branch_trade
					additional_showrooms
					tag {
						tag_name
					}
					tag_name
					state {
						state_name
						abbreviation
						canada
						province
					}
					by_state
					state_url
					formal_name
					branch_url
					phone_number
					local_nav {
						...CTA
					}
					greensky
					branch_disclaimer
					ratings_id
				}
			}
		}
	`);

	return data.allContentstackBranchData.nodes;
};
